<template>
  <div class="discount-cards">
    <discount-cards-head
      @refetch="refetch"
      @open_new_card_sidebar="openNewCardSidebar"
    />
    <div class="table-components">
      <discount-cards-table
        :items="items"
        :total="total"
        :is_loading="isLoading"
        @refetch="refetch"
        @open_new_card_sidebar="openNewCardSidebar"
      />
    </div>
    <new-card-sidebar @refetch="refetch" />
    <mass-addition-cards-sidebar @refetch="refetch" />
    <pagination
      :total="total"
      table_name="discont_cards"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapGetters, mapMutations } from 'vuex'
  import MassAdditionCardsSidebar from '../components/sidebars/MassAdditionCardsSidebar.vue'
  import NewCardSidebar from '../components/sidebars/NewCardSidebar.vue'
  import DiscountCardsHead from '../components/DiscountCardsHead'
  import DiscountCardsTable from '../components/DiscountCardsTable'
  export default {
    name: 'DiscountCards',
    components: {
      MassAdditionCardsSidebar,
      NewCardSidebar,
      DiscountCardsHead,
      DiscountCardsTable,
      Pagination
    },

    apollo: {
      ReadDiscount: {
        query: require('../gql/ReadDiscount.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take
              }
            }
          }
        },
        result({ data }) {
          this.SET_DISCONT_CARDS_DOCUMENT(data?.ReadDiscount)
          this.items = this.getDiscontCardsDocument?.list ?? []
          this.total = data?.ReadDiscount.total ?? null
          this.isLoading = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        items: [],
        total: null,
        isLoading: true,
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },

    computed: {
      ...mapGetters({
        getDiscontCardsDocument: 'marketing/getDiscontCardsDocument'
      })
    },

    methods: {
      ...mapMutations({
        SET_DISCONT_CARDS_DOCUMENT: 'marketing/SET_DISCONT_CARDS_DOCUMENT'
      }),

      refetch() {
        this.$apollo.queries.ReadDiscount.refetch()
      },

      openNewCardSidebar(id) {
        this.$root.$emit('bv::toggle::collapse', id)
      },

      handlerPagination(option) {
        this.pagination.skip = (option.current_page - 1) * option.take
        this.pagination.take = option.take
      }
    }
  }
</script>

<style scoped lang="scss">
  .discount-cards {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .table-components {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
</style>
