<template>
  <b-sidebar
    id="mass-addition-cards-sidebar"
    title="Массовое добавление карт"
    right
    backdrop
    shadow
    @shown="{}"
    @hidden="{}"
  >
    <template #header="{ hide }">
      Массовое добавление карт
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <form class="form-mass-add-cards">
        <div class="form-mass-add-cards__input-block">
          <label class="form-mass-add-cards__label">Количество карт</label>
          <e-input
            v-model="quantityCard"
            class="form-mass-add-cards__input"
            placeholder="Введите количество"
            type="number"
            :error="quantityCardError"
            error_text="Заполните поле"
          />
        </div>

        <div class="form-mass-add-cards__input-block">
          <label class="form-mass-add-cards__label">Номер первой карты</label>
          <e-input
            v-model="firstCardNumber"
            class="form-mass-add-cards__input"
            placeholder="Введите номер"
            type="number"
            :error="firstCardError"
            error_text="Заполните поле"
          />
        </div>

        <div class="form-mass-add-cards__suffix-prefix-block">
          <div class="form-mass-add-cards__input-block">
            <label class="form-mass-add-cards__label">Префикс карт</label>
            <e-input
              v-model="cardPrefix"
              class="form-mass-add-cards__input"
              placeholder="Введите префикс"
              type="text"
            />
          </div>

          <div class="form-mass-add-cards__input-block">
            <label class="form-mass-add-cards__label">Суффикс карт</label>
            <e-input
              v-model="cardSuffix"
              class="form-mass-add-cards__input"
              placeholder="Введите суффикс"
              type="text"
            />
          </div>
        </div>

        <div class="form-mass-add-cards__input-block">
          <label class="form-mass-add-cards__label">Длинна генерируемого кода</label>
          <e-input
            v-model="lengthGeneratedCode"
            class="form-mass-add-cards__input"
            placeholder="Введите длину"
            type="number"
            :error="lengthGeneratedCodeError"
            error_text="Заполните поле"
          />
        </div>

        <div class="form-mass-add-cards__switch-block">
          <b-form-checkbox
            v-model="isSwitchActive"
            name="check-button"
            switch
          />
          <div class="form-mass-add-cards__label-switch">Действует</div>
        </div>
      </form>
    </template>

    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="setMassAdditionCards(hide)"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  export default {
    name: 'MassAdditionCardsSidebar',

    data() {
      return {
        quantityCard: '',
        firstCardNumber: '',
        cardPrefix: '',
        cardSuffix: '',
        lengthGeneratedCode: '',
        isSwitchActive: true,
        quantityCardError: false,
        firstCardError: false,
        lengthGeneratedCodeError: false
      }
    },

    methods: {
      async setMassAdditionCards(hide) {
        if (this.validateFields()) return

        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/GenerateDiscount.gql'),
            variables: {
              input: {
                count: +this.quantityCard,
                firstCardNumber: +this.firstCardNumber,
                prefix: this.cardPrefix,
                suffix: this.cardSuffix,
                gen_length: +this.lengthGeneratedCode,
                blocked: !this.isSwitchActive
              }
            }
          })
          this.$noty.show(`Успешно`)
          this.clear()
          this.$emit('refetch')
          hide()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      validateFields() {
        if (this.quantityCard === '') {
          this.quantityCardError = true
          return true
        } else this.quantityCardError = false

        if (this.firstCardNumber === '') {
          this.firstCardError = true
          return true
        } else this.firstCardError = false

        if (this.lengthGeneratedCode === '') {
          this.lengthGeneratedCodeError = true
          return true
        } else this.engthGeneratedCodeError = false
      },

      clear() {
        this.quantityCard = ''
        this.firstCardNumber = ''
        this.cardPrefix = ''
        this.cardSuffix = ''
        this.lengthGeneratedCode = ''
        this.isSwitchActive = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-mass-add-cards {
    padding: 22px;

    &__input-block {
      margin-bottom: 22px;
      width: 100%;
    }
    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__suffix-prefix-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    &__switch-block {
      display: flex;
      align-items: center;
    }

    &__label-switch {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  ::v-deep() {
    #mass-addition-cards-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }

  .b-sidebar-footer {
    .b-action-buttons {
      padding: 0 22px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
</style>
