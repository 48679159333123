<template>
  <b-modal
    id="excel-import-modal"
    body-class="p-0"
    size="xl"
    hide-footer
    centered
    @hide="clear"
  >
    <template #modal-header="{ hide }">
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <div class="header-block-title">
            <h5 class="title">Импорт карт</h5>
          </div>

          <b-button class="close">
            <img
              src="/img/icons/modal_close.svg"
              alt=""
              @click="hide"
            />
          </b-button>
        </div>
      </div>
    </template>

    <template>
      <div class="modal-body">
        <div class="modal-body__head">
          <div class="modal-body__head-input-block">
            <label class="modal-body__head-input-lable">Строки</label>
            <b-form-input
              id="rows-input"
              v-model="rows"
              type="number"
              class="w-100"
              placeholder="1-5"
              :class="{ error: is_range_error }"
            />
          </div>
          <div class="modal-body__head-button-block">
            <b-button
              variant="outline-primary"
              class="cursor"
              @click="closeModal"
            >
              Отмена
            </b-button>
            <b-button
              variant="primary"
              class="cursor ml-3"
              @click="imports"
            >
              Импортировать
            </b-button>
          </div>
        </div>

        <b-card
          no-body
          class="m-3 p-0"
          style="height: 65vh"
        >
          <xlsx-read
            :file="file"
            @parsed="getSheets"
          >
            <xlsx-json
              :sheet="selectedSheet"
              :options="{ header: 'A' }"
              @parsed="setSheets"
            />
          </xlsx-read>

          <div class="w-100 overflow-auto h-100">
            <div
              :style="{ width: fields.length * 200 + 'px' }"
              class="h-100 d-flex flex-column"
            >
              <div class="d-flex w-100">
                <div
                  v-for="(field, index) in fields"
                  :key="index"
                  class="virtual-field"
                >
                  <div>
                    <i-select
                      v-model="selectedValue[index]"
                      filterable
                      clearable
                      @on-change="setColumn(index)"
                    >
                      <i-option
                        v-for="option of columns"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </i-option>
                    </i-select>
                  </div>
                  <div class="label">
                    {{ field.label }}
                  </div>
                </div>
              </div>
              <virtual-list
                ref="virtual"
                style="overflow-y: auto"
                :data-key="'id'"
                :wrap-style="{ width: '100%', overflow: 'hidden' }"
                :data-sources="parsed"
                :data-component="ItemRow"
              />
            </div>
          </div>
        </b-card>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { XlsxRead, XlsxJson } from 'vue-xlsx'
  import ItemRow from '@/views/operational-processes/components/excel/ItemRow.vue'
  import moment from 'moment'
  export default {
    name: 'ExcelImportModal',

    components: {
      XlsxRead,
      XlsxJson
    },

    props: {
      file: {
        type: File,
        default: null
      }
    },

    data() {
      return {
        rows: '',
        parsed: [],
        sheets: [],
        fields: [],
        tableMapping: [],
        selectedValue: [],
        selectedSheet: null,
        is_range_error: false,
        columns: [
          { label: '№ карты', value: 'number' },
          { label: 'Имя', value: 'name' },
          { label: 'Фамилия', value: 'surname' },
          { label: 'Отчество', value: 'patronymic' },
          { label: 'Дата рождения', value: 'birthday' },
          { label: 'Телефон', value: 'phone' },
          { label: 'Пол', value: 'gender' },
          // { label: 'Статус', value: 'blocked' },
          { label: 'Префика', value: 'prefix' },
          { label: 'Суффикс', value: 'suffix' }
        ]
      }
    },

    computed: {
      ItemRow() {
        return ItemRow
      }
    },

    methods: {
      getSheets(e) {
        this.sheets.push(...e.SheetNames)
        this.selectedSheet = this.sheets[0]
      },

      toNormalDate(val) {
        const parts = val.split('.')
        return [parts[2], parts[1], parts[0]].join('-')
      },

      setSheets(e) {
        this.parsed = e.map((el, index) => {
          return { id: index + 1, ...el }
        })
        this.fields = Object.keys(this.parsed[0]).map((el) => {
          if (el === 'id') return { label: '№', key: 'id', thClass: 'p-1 text-left' }
          return { label: el, key: el, thClass: 'p-1 text-left' }
        })
      },

      closeModal() {
        this.$bvModal.hide('excel-import-modal')
      },

      clear() {
        this.rows = ''
        this.parsed = []
        this.sheets = []
        this.fields = []
        this.tableMapping = []
        this.selectedValue = []
        this.selectedSheet = null
        this.is_range_error = false
      },

      setColumn(index) {
        this.tableMapping[index] = {
          value: this.selectedValue[index],
          label: this.fields[index].key
        }
      },

      getGenderEnum(gender) {
        console.log(gender)
        if (gender.toUpperCase() === 'M' || gender.toUpperCase() === 'М') return 'male'
        if (gender.toUpperCase() === 'Ж') return 'female'
      },

      async importCards(list) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/ImportCards.gql'),
            variables: {
              input: {
                list: list
              }
            }
          })
          this.$noty.show(`Успешно`)
          this.$emit('import_cards')
          this.clear()
          this.closeModal()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      async imports() {
        const importList = []

        for (let i = 1; this.parsed.length > i; i++) {
          const parsedEl = this.parsed[i]

          const list = {
            customer: {}
          }

          for (let j = 1; this.tableMapping.length > j; j++) {
            const tabMap = this.tableMapping[j]

            if (
              tabMap.value === 'name' ||
              tabMap.value === 'surname' ||
              tabMap.value === 'patronymic' ||
              tabMap.value === 'phone' ||
              tabMap.value === 'gender' ||
              tabMap.value === 'birthday'
            ) {
              if (tabMap.value === 'birthday') {
                list['customer'][tabMap.value] = moment(this.toNormalDate(parsedEl[tabMap.label])).toISOString()
              } else if (tabMap.value === 'phone') {
                list['customer'][tabMap.value] = parsedEl[tabMap.label].toString()
              } else if (tabMap.value === 'gender' && parsedEl[tabMap.label]) {
                list['customer'][tabMap.value] = this.getGenderEnum(parsedEl?.[tabMap?.label])
              } else list['customer'][tabMap.value] = parsedEl[tabMap.label]
            } else {
              if (tabMap.value === 'number') {
                list[tabMap.value] = parsedEl[tabMap.label].toString()
              } else if (parsedEl[tabMap.label] === 'Действует') {
                list[tabMap.value] = true
              } else if (parsedEl[tabMap.label] === 'Остановлена') {
                list[tabMap.value] = false
              } else list[tabMap.value] = parsedEl[tabMap.label]
            }
          }
          importList.push(list)
        }

        this.importCards(importList)
      }
    }
  }
</script>

<style scoped lang="scss">
  .header-container {
    width: 100%;
    .header-block-title {
      display: flex;
      align-items: center;
    }
    .title-block {
      margin-bottom: 16px;
      .title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  :deep .card {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .modal-body {
    height: 100%;

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__head-button-block {
      display: flex;
    }
  }
  .virtual-field {
    width: 200px;
    .label {
      color: #888;
      padding: 3px 16px;
    }
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
</style>
