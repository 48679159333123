var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "discount-cards"
  }, [_c('discount-cards-head', {
    on: {
      "refetch": _vm.refetch,
      "open_new_card_sidebar": _vm.openNewCardSidebar
    }
  }), _c('div', {
    staticClass: "table-components"
  }, [_c('discount-cards-table', {
    attrs: {
      "items": _vm.items,
      "total": _vm.total,
      "is_loading": _vm.isLoading
    },
    on: {
      "refetch": _vm.refetch,
      "open_new_card_sidebar": _vm.openNewCardSidebar
    }
  })], 1), _c('new-card-sidebar', {
    on: {
      "refetch": _vm.refetch
    }
  }), _c('mass-addition-cards-sidebar', {
    on: {
      "refetch": _vm.refetch
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "discont_cards"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }