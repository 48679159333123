var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "mass-addition-cards-sidebar",
      "title": "Массовое добавление карт",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": function ($event) {
        {}
      },
      "hidden": function ($event) {
        {}
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Массовое добавление карт "), _c('div', {
          staticClass: "header-left-block"
        }, [_c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.setMassAdditionCards(hide);
            }
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [[_c('form', {
    staticClass: "form-mass-add-cards"
  }, [_c('div', {
    staticClass: "form-mass-add-cards__input-block"
  }, [_c('label', {
    staticClass: "form-mass-add-cards__label"
  }, [_vm._v("Количество карт")]), _c('e-input', {
    staticClass: "form-mass-add-cards__input",
    attrs: {
      "placeholder": "Введите количество",
      "type": "number",
      "error": _vm.quantityCardError,
      "error_text": "Заполните поле"
    },
    model: {
      value: _vm.quantityCard,
      callback: function ($$v) {
        _vm.quantityCard = $$v;
      },
      expression: "quantityCard"
    }
  })], 1), _c('div', {
    staticClass: "form-mass-add-cards__input-block"
  }, [_c('label', {
    staticClass: "form-mass-add-cards__label"
  }, [_vm._v("Номер первой карты")]), _c('e-input', {
    staticClass: "form-mass-add-cards__input",
    attrs: {
      "placeholder": "Введите номер",
      "type": "number",
      "error": _vm.firstCardError,
      "error_text": "Заполните поле"
    },
    model: {
      value: _vm.firstCardNumber,
      callback: function ($$v) {
        _vm.firstCardNumber = $$v;
      },
      expression: "firstCardNumber"
    }
  })], 1), _c('div', {
    staticClass: "form-mass-add-cards__suffix-prefix-block"
  }, [_c('div', {
    staticClass: "form-mass-add-cards__input-block"
  }, [_c('label', {
    staticClass: "form-mass-add-cards__label"
  }, [_vm._v("Префикс карт")]), _c('e-input', {
    staticClass: "form-mass-add-cards__input",
    attrs: {
      "placeholder": "Введите префикс",
      "type": "text"
    },
    model: {
      value: _vm.cardPrefix,
      callback: function ($$v) {
        _vm.cardPrefix = $$v;
      },
      expression: "cardPrefix"
    }
  })], 1), _c('div', {
    staticClass: "form-mass-add-cards__input-block"
  }, [_c('label', {
    staticClass: "form-mass-add-cards__label"
  }, [_vm._v("Суффикс карт")]), _c('e-input', {
    staticClass: "form-mass-add-cards__input",
    attrs: {
      "placeholder": "Введите суффикс",
      "type": "text"
    },
    model: {
      value: _vm.cardSuffix,
      callback: function ($$v) {
        _vm.cardSuffix = $$v;
      },
      expression: "cardSuffix"
    }
  })], 1)]), _c('div', {
    staticClass: "form-mass-add-cards__input-block"
  }, [_c('label', {
    staticClass: "form-mass-add-cards__label"
  }, [_vm._v("Длинна генерируемого кода")]), _c('e-input', {
    staticClass: "form-mass-add-cards__input",
    attrs: {
      "placeholder": "Введите длину",
      "type": "number",
      "error": _vm.lengthGeneratedCodeError,
      "error_text": "Заполните поле"
    },
    model: {
      value: _vm.lengthGeneratedCode,
      callback: function ($$v) {
        _vm.lengthGeneratedCode = $$v;
      },
      expression: "lengthGeneratedCode"
    }
  })], 1), _c('div', {
    staticClass: "form-mass-add-cards__switch-block"
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.isSwitchActive,
      callback: function ($$v) {
        _vm.isSwitchActive = $$v;
      },
      expression: "isSwitchActive"
    }
  }), _c('div', {
    staticClass: "form-mass-add-cards__label-switch"
  }, [_vm._v("Действует")])], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }