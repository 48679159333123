var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "excel-import-modal",
      "body-class": "p-0",
      "size": "xl",
      "hide-footer": "",
      "centered": ""
    },
    on: {
      "hide": _vm.clear
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('div', {
          staticClass: "header-block-title"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Импорт карт")])]), _c('b-button', {
          staticClass: "close"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])], 1)])];
      }
    }])
  }, [[_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "modal-body__head"
  }, [_c('div', {
    staticClass: "modal-body__head-input-block"
  }, [_c('label', {
    staticClass: "modal-body__head-input-lable"
  }, [_vm._v("Строки")]), _c('b-form-input', {
    staticClass: "w-100",
    class: {
      error: _vm.is_range_error
    },
    attrs: {
      "id": "rows-input",
      "type": "number",
      "placeholder": "1-5"
    },
    model: {
      value: _vm.rows,
      callback: function ($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  })], 1), _c('div', {
    staticClass: "modal-body__head-button-block"
  }, [_c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Отмена ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.imports
    }
  }, [_vm._v(" Импортировать ")])], 1)]), _c('b-card', {
    staticClass: "m-3 p-0",
    staticStyle: {
      "height": "65vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('xlsx-read', {
    attrs: {
      "file": _vm.file
    },
    on: {
      "parsed": _vm.getSheets
    }
  }, [_c('xlsx-json', {
    attrs: {
      "sheet": _vm.selectedSheet,
      "options": {
        header: 'A'
      }
    },
    on: {
      "parsed": _vm.setSheets
    }
  })], 1), _c('div', {
    staticClass: "w-100 overflow-auto h-100"
  }, [_c('div', {
    staticClass: "h-100 d-flex flex-column",
    style: {
      width: _vm.fields.length * 200 + 'px'
    }
  }, [_c('div', {
    staticClass: "d-flex w-100"
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('div', {
      key: index,
      staticClass: "virtual-field"
    }, [_c('div', [_c('i-select', {
      attrs: {
        "filterable": "",
        "clearable": ""
      },
      on: {
        "on-change": function ($event) {
          return _vm.setColumn(index);
        }
      },
      model: {
        value: _vm.selectedValue[index],
        callback: function ($$v) {
          _vm.$set(_vm.selectedValue, index, $$v);
        },
        expression: "selectedValue[index]"
      }
    }, _vm._l(_vm.columns, function (option) {
      return _c('i-option', {
        key: option.value,
        attrs: {
          "value": option.value
        }
      }, [_vm._v(" " + _vm._s(option.label) + " ")]);
    }), 1)], 1), _c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(field.label) + " ")])]);
  }), 0), _c('virtual-list', {
    ref: "virtual",
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "data-key": 'id',
      "wrap-style": {
        width: '100%',
        overflow: 'hidden'
      },
      "data-sources": _vm.parsed,
      "data-component": _vm.ItemRow
    }
  })], 1)])], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }