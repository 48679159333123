<template>
  <div class="discount-cards-head">
    <excel-import-modal
      :file="file"
      @import_cards="importCards"
    />

    <div class="discount-cards-head__content">
      <div class="discount-cards-head__select-block">
        <div class="discount-cards-head__select-item">
          <e-select
            :value="currentyTpes"
            class="e-select-el"
            :options="allTypes"
            multiple
            :hide_bin="true"
            :max_selected_count="1"
            :background_color="'#e0edff'"
            :color_text="'#00A3FF'"
            :color_close_svg="'#00A3FF'"
            placeholder="Все типы"
            select_name="roles_select"
            @input="
              {
              }
            "
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>

        <div class="discount-cards-head__select-item">
          <e-select
            :value="currentyRules"
            class="e-select-el"
            :options="allRules"
            multiple
            :hide_bin="true"
            :max_selected_count="1"
            :background_color="'#e0edff'"
            :color_text="'#00A3FF'"
            :color_close_svg="'#00A3FF'"
            placeholder="Все правила"
            select_name="roles_select"
            @input="
              {
              }
            "
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>
      </div>

      <div class="discount-cards-head__button-block">
        <div>
          <div class="b-table__actions ml-auto">
            <b-button
              class="cursor"
              variant="primary"
              @click="openNewCardSidebar"
            >
              <img
                src="/img/icons/btn-plus.svg"
                class="mr"
                alt=""
              />
              Добавить карту
            </b-button>
            <input
              id="fileUploadCard"
              type="file"
              hidden
              :v-model="file"
              @input="setFile"
            />
            <b-dropdown
              no-caret
              variant="none"
              class="ml-3"
            >
              <template #button-content>
                <div class="btn-more">
                  <img
                    src="/img/icons/more.svg"
                    alt=""
                  />
                </div>
              </template>
              <b-dropdown-item
                v-b-toggle.mass-addition-cards-sidebar
                @click="
                  {
                  }
                "
              >
                Массовое добавление
              </b-dropdown-item>
              <b-dropdown-item @click="chooseFiles"> Импорт из Excel </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import ExcelImportModal from './modals/ExcelImportModal.vue'
  export default {
    name: 'DiscountCardsHead',

    components: {
      ExcelImportModal
    },

    data() {
      return {
        currentyTpes: [],
        currentyRules: [],
        file: null,
        allTypes: [
          { id: 12, name: 'Скидочные' },
          { id: 11, name: 'Бонусные' }
        ],
        allRules: [
          { id: 12, name: 'Накопительная скидка' },
          { id: 11, name: 'День рождения' },
          { id: 10, name: 'Пол' }
        ]
      }
    },

    methods: {
      ...mapMutations({
        SET_DISCONT_CARD: 'marketing/SET_DISCONT_CARD'
      }),

      importCards() {
        this.$emit('refetch')
      },

      chooseFiles() {
        document.getElementById('fileUploadCard').click()
      },

      openNewCardSidebar() {
        this.SET_DISCONT_CARD({})
        this.$emit('open_new_card_sidebar', 'new-card-sidebar')
      },

      async setFile(e) {
        this.file = e.target.files[0]
        this.$bvModal.show('excel-import-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .e-select-el__option-name {
    margin-bottom: 0px !important;
  }
  :deep .search {
    width: 1px;
  }
  .discount-cards-head {
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__select-block {
      display: flex;
      align-items: center;
    }

    &__select-item {
      width: 280px;
      margin-right: 25px;
    }

    &__button-block {
      display: flex;
      align-items: center;
    }
  }
</style>
