<template>
  <div class="d-flex item-row">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="item"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ItemRow',
    props: {
      index: {
        // index of current item
        type: Number
      },
      source: {
        // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default() {
          return {}
        }
      }
    },
    computed: {
      items() {
        return Object.values(this.source)
      }
    }
  }
</script>

<style scoped lang="scss">
  .item-row {
    border-bottom: 1px solid #dee2e6;

    &:hover {
      background: #f8fbff;
    }
  }

  .item {
    padding: 8px 16px 8px 16px;
    flex: 1;
    max-width: 200px;
    min-width: 200px;
  }
</style>
