<template>
  <b-sidebar
    id="new-card-sidebar"
    title="Новая карта"
    right
    backdrop
    shadow
    @shown="{}"
    @hidden="{}"
  >
    <template #header="{ hide }">
      <div>{{ getDiscontCard?.id ? 'Редактировать' : 'Новая карта' }}</div>
      <div class="header-left-block">
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </div>
    </template>

    <template>
      <form class="form-new-card">
        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Номер карты</label>
          <div v-if="getDiscontCard?.id">{{ getDiscontCard?.getNumber }}</div>
          <e-input
            v-else
            :value="getDiscontCard?.getNumber"
            class="form-new-card__input"
            placeholder="Введите номер"
            type="number"
            :error="numberCardError"
            error_text="Заполните поле"
            @change="changeNumber"
          />
        </div>

        <div class="form-new-card__suffix-prefix-block">
          <div class="form-new-card__input-block">
            <label class="form-new-card__label">Префикс карты</label>
            <e-input
              :value="getDiscontCard?.getPrefix"
              class="form-new-card__input"
              placeholder="Введите префикс"
              type="text"
              @change="changePrefix"
            />
          </div>

          <div class="form-new-card__input-block">
            <label class="form-new-card__label">Суффикс карты</label>
            <e-input
              :value="getDiscontCard?.getSuffix"
              class="form-new-card__input"
              placeholder="Введите суффикс"
              type="text"
              @change="changeSuffix"
            />
          </div>
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Имя</label>
          <e-input
            :value="getDiscontCard?.getCustomerName"
            class="form-new-card__input"
            placeholder="Введите имя"
            type="text"
            @change="changeName"
          />
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Фамилия</label>
          <e-input
            :value="getDiscontCard?.getCustomerSurname"
            class="form-new-card__input"
            placeholder="Введите фамилию"
            type="text"
            @change="changeSurname"
          />
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Отчество</label>
          <e-input
            :value="getDiscontCard?.getCustomerPatronymic"
            class="form-new-card__input"
            placeholder="Введите отчество"
            type="text"
            @change="changePatronymic"
          />
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Пол</label>
          <e-select
            :value="getDiscontCard?.getCustomerGender"
            class="e-select-el"
            :options="genderList"
            :background_color="'#e0edff'"
            :color_text="'#00A3FF'"
            :color_close_svg="'#00A3FF'"
            placeholder="Выберите пол"
            select_name="operations_select"
            @change="changeGender"
          >
            <template #custom_name="{ item }">
              <div>
                <div class="e-select-el__option-name">{{ item.name }}</div>
              </div>
            </template>
          </e-select>
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Дата рождения</label>
          <div class="modal-content__input-block">
            <div class="b-filter-date">
              <date-picker
                :value="getDiscontCard?.getCustomerBirthday"
                format="dd.MM.yyyy"
                :clearable="false"
                class="elm-calendar date"
                placeholder="Выберите дату"
                transfer
                @on-change="changeDate"
              />
            </div>
          </div>
        </div>

        <div class="form-new-card__input-block">
          <label class="form-new-card__label">Телефон</label>
          <b-form-input
            v-mask="'+7##########'"
            :value="getDiscontCard?.getCustomerPhone"
            placeholder="+7XXXXXXXXXX"
            type="text"
            required
            @input="changePhone"
          />
        </div>

        <div
          v-if="!getDiscontCard?.id"
          class="form-new-card__switch-block"
        >
          <b-form-checkbox
            :checked="getDiscontCard?.getBlocked"
            name="check-button"
            switch
            @change="changeBlocked"
          />
          <div class="form-new-card__label-switch">Действует</div>
        </div>
      </form>
    </template>

    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="saveDiscontCard(hide)"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'NewCardSidebar',

    data() {
      return {
        numberCardError: false,
        genderList: [
          { id: 1, name: 'М', value: 'male' },
          { id: 2, name: 'Ж', value: 'female' }
        ]
      }
    },
    computed: {
      ...mapGetters({
        getDiscontCard: 'marketing/getDiscontCard'
      })
    },

    methods: {
      ...mapMutations({
        SET_DISCONT_CARD_PARAM: 'marketing/SET_DISCONT_CARD_PARAM'
      }),

      async saveDiscontCard(hide) {
        if (this.getDiscontCard?.id) {
          await this.updateCustomer(hide)
        } else {
          await this.createDiscontCard(hide)
        }
      },

      async createDiscontCard(hide) {
        if (!this.getDiscontCard?.getNumber) return (this.numberCardError = true)
        else this.numberCardError = false

        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/CreateDiscount.gql'),
            variables: {
              input: this.getDiscontCard?.getInputDiscontCard
            }
          })
          this.$noty.show(`Успешно`)
          this.$emit('refetch')
          hide()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      async updateCustomer(hide) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/UpdateCustomer.gql'),
            variables: {
              input: this.getDiscontCard?.customer.inputDiscontCustomer
            }
          })
          this.$noty.show(`Успешно`)
          this.$emit('refetch')
          hide()
        } catch (err) {
          this.$noty.error('Ошибка')
          console.log(err)
        }
      },

      changePrefix(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'prefix', value: value })
      },

      changeSuffix(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'suffix', value: value })
      },

      changeNumber(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'number', value: value })
      },

      changeName(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'name', value: value })
      },

      changeSurname(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'surname', value: value })
      },

      changePatronymic(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'patronymic', value: value })
      },

      changePhone(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'phone', value: value })
      },

      changeGender(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'gender', value: value })
      },

      changeDate(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'birthday', value: this.toNormalDate(value) })
      },

      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      changeBlocked(value) {
        this.SET_DISCONT_CARD_PARAM({ key: 'blocked', value: value })
      }
    }
  }
</script>

<style scoped lang="scss">
  .form-new-card {
    padding: 22px;

    &__input-block {
      margin-bottom: 22px;
      width: 100%;
    }
    &__label {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 4px;
    }

    &__switch-block {
      display: flex;
      align-items: center;
    }

    &__label-switch {
      color: var(--text-primary-gray-700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__suffix-prefix-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }

  ::v-deep() {
    #new-card-sidebar {
      width: 450px;
    }

    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      display: flex;
      justify-content: space-between;

      .header-left-block {
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
    }

    .b-sidebar-body {
      background: #fff;
      padding: 0;
    }
  }

  .b-sidebar-footer {
    .b-action-buttons {
      padding: 0 22px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
</style>
