var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex item-row"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }